import React, { useRef } from 'react'
import './Join.css'
import emailjs from '@emailjs/browser';
const Join = () => {
    const form = useRef()

    const sendEmail = (e) => {
        e.preventDefault();
        console.log("Sending email...", form.current);
    
        emailjs.sendForm('service_38p7ie1', 'template_wfzvs3g', form.current, 'jdp5aRcb8GDPdsQ9O')
          .then((result) => {
              console.log('Email successfully sent!', result.text);
          }, (error) => {
              console.log('Failed to send email:', error.text);
          });
    };
    
  return (
    <div className="Join">
        <div className="left-j" id='join-us'>
        <div>
            <hr style={{border: '2px solid orange',borderRadius:'20%',width: '10.5rem',position:'absolute',margin:'-10px 0'
}}/>
            <span className='stroke-text'>READY TO</span>
            <span>LEVEL UP</span>
        </div>
        <div>
            <span>YOUR BODY</span>
            <span className='stroke-text'>WITH US?</span>
            </div>
        </div>
        <div className="right-j">
            <form ref={form} className="email-container" onSubmit={sendEmail}>
                <input type="email" name="user_email" placeholder='Enter your Email address' />
                <button className='btn btn-j'>Join Now</button>
            </form>
        </div>
    </div>
  )
}
export default Join

